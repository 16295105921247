import { useCountryList } from "Hooks/Options/useCountryList";
import { useEffect, useState } from "react";

export const usePersonalInfNumberValidatedCountries = () => {
  const { countries } = useCountryList();
  const [validatedCountries, setValidatedCountries] = useState<number[]>([]);

  useEffect(() => {
    setValidatedCountries(
      () =>
        countries
          ?.filter(({ alpha2Code }) => alpha2Code === "CZ")
          ?.map(({ id }) => id) ?? [],
    );
  }, [countries]);

  return { validatedCountries };
};
