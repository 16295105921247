import styled from "styled-components";

type AlignItemsType = "center" | "flex-end" | "flex-start" | "stretch";
type JustifyContentType =
  | "center"
  | "flex-end"
  | "flex-start"
  | "space-between";

export const StyledFlex = styled.div<{
  alignItems?: AlignItemsType;
  justifyContent?: JustifyContentType;
  mdFlexFlow?: "row" | "column";
  gap?: string;
}>`
  display: flex;
  align-items: ${props => (props.alignItems ? props.alignItems : "")};
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : ""};
  gap: ${props => (props.gap ? props.gap : "")};

  ${props => props.theme.breakpoints.down("lg")} {
    flex-flow: ${props => (props.mdFlexFlow ? props.mdFlexFlow : "")};
  }
`;

export const StyledInputLabel = styled.label<{ hasError?: boolean }>`
  font-weight: 400;
  font-size: 12px;
  display: flex;
  align-items: center;
  color: ${props =>
    props.hasError ? props.theme.colors.red : props.theme.palette.text.primary};
  margin-bottom: ${props => props.theme.spacing(2)};
  z-index: 1;

  .MuiTypography-body1 {
    font-weight: 400;
  }
`;

export const StyledPageWrapper = styled.div`
  max-width: 600px;
  min-width: 600px;
  height: 100%;

  .left-content {
    padding-right: ${props => props.theme.spacing(10)};
    order: 0;
  }

  ${props => props.theme.breakpoints.down("lg")} {
    .left-content {
      padding-right: ${props => props.theme.spacing(6)};
    }
  }

  ${props => props.theme.breakpoints.down("md")} {
    max-width: unset;
    min-width: unset;

    .left-content {
      padding: 0;
    }

    .right-content + .left-content {
      display: none;
    }
  }
`;
