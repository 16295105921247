import { put, takeLatest } from "redux-saga/effects";
import { getCodeList } from "Infrastructure/Api/Api";
import { getType } from "typesafe-actions";
import { call } from "typed-redux-saga";
import { getCodeListCollectionAsync } from "State/CodeLists/CodeListReducer";

function* getCodeListCollection(
  action: ReturnType<typeof getCodeListCollectionAsync.request>,
): Generator {
  try {
    const response = yield* call(getCodeList);
    if (response.status === 200) {
      yield put(getCodeListCollectionAsync.success(response.data));
    } else {
      yield put(
        getCodeListCollectionAsync.failure(
          new Error(`Error in action ${action.type}`, {
            cause: response.status,
          }),
        ),
      );
    }
  } catch (err) {
    yield put(getCodeListCollectionAsync.failure(err as Error));
  }
}
export function* getCodeListCollectionSaga() {
  yield takeLatest(
    getType(getCodeListCollectionAsync.request),
    getCodeListCollection,
  );
}
