import { useMutation } from "@tanstack/react-query";
import { postVerifications } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const useVerificationMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse(() => postVerifications()),
    onSuccess: () => {
      onSuccess?.();
    },
  });
