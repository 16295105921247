import { ValueItems } from "Components/Onboarding/Steps/Components/ValueItems";
import { ValuesBox } from "Components/Onboarding/Steps/Components/ValuesBox";
import { FC } from "react";
import { usePersonalInformationValues } from "../Hooks/usePersonalInformationValues";
import { Resources, useResource } from "Translations/Resources";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type Props = {
  onEdit: () => void;
};

export const ViewValues: FC<Props> = ({ onEdit }) => {
  const { t } = useResource();
  const [personalInfo, address, identityDocument] =
    usePersonalInformationValues();

  return (
    <>
      <ValuesBox
        title={t(StepResources.FormGroups.PersonalInformation)}
        onEdit={onEdit}
      >
        <ValueItems values={personalInfo} />
      </ValuesBox>
      <ValuesBox title={t(StepResources.FormGroups.Address)} onEdit={onEdit}>
        <ValueItems values={address} />
      </ValuesBox>
      <ValuesBox
        title={t(StepResources.FormGroups.IdentityDocument)}
        onEdit={onEdit}
      >
        <ValueItems values={identityDocument} />
      </ValuesBox>
    </>
  );
};
