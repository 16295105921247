import { Box, Typography } from "@mui/material";
import { AlertIcon } from "Components/Shared/Icons";
import { FC, PropsWithChildren } from "react";
import { useTheme } from "styled-components";

export const AlertBox: FC<PropsWithChildren<unknown>> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={5}
      paddingY={2}
      paddingX={4}
      borderRadius={1}
      border={1}
      borderColor={theme.colors.red}
    >
      <div>
        <AlertIcon fill={theme.colors.red} />
      </div>
      <Typography color="red">{children}</Typography>
    </Box>
  );
};
