import { Box } from "@mui/material";
import { FC, PropsWithChildren } from "react";

export const FormItemsBox: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <Box display="grid" rowGap={3}>
      {children}
    </Box>
  );
};
