import { PrimaryButton } from "Components/Shared/Buttons/PrimaryButton";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { FormProvider, useForm } from "react-hook-form";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useTransactionPay } from "Api/Mutations/Transactions/useTransactionPay";
import { FC, useEffect } from "react";
import { FormFields } from "Components/Customer/Payment/FormFields";
import { ApiCallError } from "Infrastructure/Api/ApiUtils";
import { useBankingAccounts } from "Api/Queries/useBankingAccounts";
import { Skeleton, Typography } from "@mui/material";

const ComponentResources = Resources.Customer.Pay.DescriptionText;

type Props = {
  publicID?: string | null;
  transactionPublicID?: string | null;
};

export type FormModel = {
  bankAccountID: number;
  publicID: string;
};

const PageResources = Resources.Customer.Pay;

const InitiateMerchantSchema: z.ZodType<FormModel> = z.object({
  bankAccountID: z.number(),
  publicID: z.string().min(1),
});

export const CustomerPaymentPayComponent: FC<Props> = ({
  publicID,
  transactionPublicID,
}) => {
  const { t } = useResource();
  const { data, isLoading } = useBankingAccounts();
  const form = useForm<FormModel>({
    resolver: zodResolver(InitiateMerchantSchema),
    defaultValues: {
      publicID: transactionPublicID ?? undefined,
    },
  });
  const { handleSubmit, setError, setValue, getValues } = form;
  const { mutateAsync: payTransaction, isPending } = useTransactionPay();

  useEffect(() => {
    if (!getValues()?.bankAccountID) {
      let bankAccountID: number | undefined = undefined;

      if (!publicID) {
        bankAccountID = data?.items?.sort((a, b) => b.rank - a.rank)?.[0]
          ?.bankAccountID;
      } else {
        bankAccountID = data?.items?.find(
          x => x.publicID === publicID,
        )?.bankAccountID;
      }

      if (bankAccountID) {
        setValue("bankAccountID", bankAccountID);
      }
    }
  }, [publicID, data, getValues, setValue]);

  const onSubmit = async ({ bankAccountID, publicID }: FormModel) => {
    try {
      const result = await payTransaction({
        publicID: publicID,
        senderBankAccountID: bankAccountID,
      });

      if (result.signingUrl) {
        window.location.href = result.signingUrl;
      }
    } catch (error) {
      if (error instanceof ApiCallError) {
        const status = error.data.status;

        // TODO: No 404 status code in API definitions??
        // @ts-ignore
        if (status === 404) {
          setError("publicID", { message: "Neplatný identifikátor transakce" });
        }

        // @ts-ignore
        if (status === 403) {
          setError("publicID", { message: "Transakce již byla uhrazena" });
        }
      }
    }
  };

  return (
    <PrimaryPaper>
      <Typography fontWeight={700} marginBottom={2}>
        {t(ComponentResources)}
      </Typography>

      {isLoading && !data ? (
        <>
          <Skeleton height={270}></Skeleton>
          <Skeleton height={62}></Skeleton>
        </>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormProvider {...form}>
            <FormFields bankAccounts={data?.items} isLoading={isLoading} />
          </FormProvider>
          <PrimaryButton
            fullWidth
            type="submit"
            color="primary"
            disabled={isPending}
          >
            {t(PageResources.Title)}
          </PrimaryButton>
        </form>
      )}
    </PrimaryPaper>
  );
};
