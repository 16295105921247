import { Box } from "@mui/material";
import { OnboardingLayout } from "Components/Layout/OnboardingLayout";
import {
  OnboardingContextProvider,
  useOnboardingContext,
} from "Components/Onboarding/Context/OnboardingContext";
import { OnboardingComponent } from "Components/Onboarding/OnboardingComponent";
import { StyledPageWrapper } from "Components/Shared/StyledComponents";
import { FC } from "react";
import { Resources, useResource } from "Translations/Resources";

const PageContent: FC = () => {
  const { t } = useResource();
  const { prevStep, hasPrevStep } = useOnboardingContext();

  return (
    <OnboardingLayout
      title={t(Resources.Onboarding.ProcessTitle)}
      onChevronClick={hasPrevStep ? prevStep : undefined}
    >
      <StyledPageWrapper>
        <Box height="100%" className="left-content">
          <OnboardingComponent />
        </Box>
      </StyledPageWrapper>
    </OnboardingLayout>
  );
};

export const OnboardingPage: FC = () => {
  return (
    <OnboardingContextProvider>
      <PageContent />
    </OnboardingContextProvider>
  );
};
