import { TextFieldProps } from "@mui/material";
import { DragNDrop } from "Components/Shared/DragNDrop";
import { StyledInputLabel } from "Components/Shared/StyledComponents";
import { useId } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = TextFieldProps & {
  name: FieldPath<FormType>;
  label?: string;
  control: Control<FormType, object>;
  disabled?: boolean;
  type?: string;
  title: string;
  description?: string;
};

export const BlFileInputForm = <T extends object>(props: Props<T>) => {
  const { name, control, label, title, description } = props;
  const inputId = useId();

  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          return (
            <div>
              {!!label && (
                <StyledInputLabel htmlFor={inputId}>{label}</StyledInputLabel>
              )}
              <DragNDrop
                file={value}
                onChange={onChange}
                title={title}
                description={description}
                id={inputId}
                isError={!!error}
              />
              {!!error && (
                <div style={{ color: "red" }}>
                  {error.message || "This field is required"}
                </div>
              )}
            </div>
          );
        }}
      />
    </>
  );
};
