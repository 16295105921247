import { zodResolver } from "@hookform/resolvers/zod";
import {
  OnboardingSteps,
  useOnboardingContext,
  UserDetailModel,
} from "Components/Onboarding/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/Steps/Components/StepBox";
import { useUserDetailValidationSchema } from "Components/Onboarding/Steps/Hooks/useUserDetailValidationSchema";
import { EditValues } from "Components/Onboarding/Steps/PersonalInformation/EditValues";
import { ViewValues } from "Components/Onboarding/Steps/PersonalInformation/ViewValues";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.PersonalInformation;

type FormModel = {
  isUserConfirmed: boolean;
};

export const PersonalInformationStep: FC = () => {
  const { t } = useResource();
  const [validationSchema] = useState<z.ZodType<FormModel>>(
    z.object({
      isUserConfirmed: z.boolean().refine(value => value, {
        message: t(StepResources.ConfirmDataRequest),
      }),
    }),
  );

  const { userDetailValidationSchema } = useUserDetailValidationSchema();
  const { getFormValues, nextStep, setFormValues } = useOnboardingContext();
  const [isEdit, setIsEdit] = useState(false);

  const { control, handleSubmit } = useForm<FormModel>({
    resolver: zodResolver(validationSchema),
  });

  const onEdit = () => {
    setIsEdit(true);
  };

  const onSave = (values: UserDetailModel) => {
    setFormValues(OnboardingSteps.UserDetail, values);
    setIsEdit(false);
  };

  const onSubmit = () => {
    if (
      userDetailValidationSchema.safeParse(
        getFormValues(OnboardingSteps.UserDetail),
      ).success
    ) {
      nextStep();

      return;
    }

    toast.error(t(Resources.Common.FillMissing));
  };

  return (
    <>
      {isEdit ? (
        <EditValues onSave={onSave} />
      ) : (
        <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
          <StepBox
            title={t(StepResources.Title)}
            submitButtonLabel={t(Resources.Common.Continue)}
            withDivider
          >
            <ViewValues onEdit={onEdit} />
            <CheckboxInput
              label={t(StepResources.DataConfirmation)}
              control={control}
              name="isUserConfirmed"
            />
          </StepBox>
        </FullHeightForm>
      )}
    </>
  );
};
