import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { usePartyMutation } from "Api/Mutations/Party/usePartyMutation";
import { useVerificationMutation } from "Api/Mutations/Verifications/useVerificationMutation";
import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/Steps/Components/StepBox";
import { ValuesBox } from "Components/Onboarding/Steps/Components/ValuesBox";
import { AlertBox } from "Components/Shared/AlertBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { CheckboxInput } from "Components/Shared/Inputs/Form/CheckboxInput";
import { FormInput } from "Components/Shared/Inputs/Form/FormInput";
import { FC } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { getUserInfoAsync } from "State/Auth/UserInfo/GetUserInfoState";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";

const validationSchema = z.object({
  phone: z.string().min(12, "Prosím, zadejte platné telefonní číslo"),
  politicallyExposed: z.boolean(),
});

const StepResource = Resources.Onboarding.Steps.FinalStep;

type FormModel = z.infer<typeof validationSchema>;

export const FinalStep: FC = () => {
  const { t } = useResource();
  const dispatch = useDispatch();
  const { mutate, isPending } = usePartyMutation({
    onSuccess: () => {
      verify();
    },
  });
  const { mutate: verify, isPending: isVerifying } = useVerificationMutation({
    onSuccess: () => {
      // after we refresh the user info with the new data, the user will be redirected to the dashboard
      dispatch(getUserInfoAsync.request());
    },
  });
  const { getPartyValues, setFormValues } = useOnboardingContext();
  const { control, handleSubmit, watch } = useForm<FormModel>({
    defaultValues: {
      phone: "420",
      politicallyExposed: false,
    },
    resolver: zodResolver(validationSchema),
  });

  const politicallyExposed = watch("politicallyExposed");

  const onSubmit = (data: FormModel) => {
    if (politicallyExposed) {
      return;
    }

    setFormValues(OnboardingSteps.LastStep, data);
    const { party, publicID } = getPartyValues();

    mutate({ data: party, publicID });
  };

  return (
    <FullHeightForm onSubmit={handleSubmit(onSubmit)}>
      <StepBox
        submitButtonLabel={t(StepResource.SubmitButton)}
        submitDisabled={politicallyExposed}
        isSubmiting={isPending || isVerifying}
      >
        <CheckboxInput
          label={t(StepResource.PolicicallyExposed)}
          control={control}
          name="politicallyExposed"
          isLabelFirst
          isStrong
        />
        <Typography marginBottom={6} marginTop={1}>
          {t(StepResource.PEPDescription)}
        </Typography>
        {!!politicallyExposed && (
          <Box marginBottom={6}>
            <AlertBox>{t(StepResource.PEPAlert)}</AlertBox>
          </Box>
        )}
        <ValuesBox title={t(Resources.Common.Contact)} hideDivider>
          <FormInput
            control={control}
            name="phone"
            label={t(Resources.Common.Phone)}
            mask="+000 000 000 000"
            inputProps={{
              inputMode: "tel",
            }}
          />
        </ValuesBox>
      </StepBox>
    </FullHeightForm>
  );
};
