import {
  OnboardingSteps,
  useOnboardingContext,
} from "Components/Onboarding/Context/OnboardingContext";
import { DocumentsStep } from "Components/Onboarding/Steps/DocumentsStep";
import { FinalStep } from "Components/Onboarding/Steps/FinalStep";
import { PersonalInformationStep } from "Components/Onboarding/Steps/PersonalInformation/PersonalInformationStep";
import { PrimaryPaper } from "Components/Shared/PrimaryPaper";
import { FC } from "react";

const StepsSelector: FC = () => {
  const { step } = useOnboardingContext();

  if (step === OnboardingSteps.PersonalDocument) {
    return <DocumentsStep />;
  } else if (step === OnboardingSteps.UserDetail) {
    return <PersonalInformationStep />;
  } else if (step === OnboardingSteps.LastStep) {
    return <FinalStep />;
  }

  return null;
};

export const OnboardingComponent: FC = () => {
  return (
    <PrimaryPaper sx={{ height: "100%" }}>
      <StepsSelector />
    </PrimaryPaper>
  );
};
