import { UserDetailModel } from "Components/Onboarding/Context/OnboardingContext";
import { usePersonalInfNumberValidatedCountries } from "Components/Onboarding/Steps/Hooks/usePersonalInfNumberValidatedCountries";
import { Gender, IdentificationType } from "Infrastructure/Api/Api";
import { useEffect, useState } from "react";
import { PersonalIdentification } from "Utils/PersonalIdentification";
import { z } from "zod";

const getUserDetailValidationSchema = (
  validatedCountries: number[] = [],
): z.ZodType<UserDetailModel> =>
  z
    .object({
      firstName: z.string(),
      lastName: z.string(),
      birthPlace: z.string(),
      country: z.number({ message: "Povinné" }),
      gender: z.nativeEnum(Gender, { message: "Povinné" }),
      personalIdentificationNumber: z.string(),
      address: z.object({
        street: z.string(),
        descriptionNumber: z.string(),
        orientationNumber: z.string().optional(),
        city: z.string(),
        postalCode: z.string(),
      }),

      document: z.object({
        type: z.nativeEnum(IdentificationType, {
          message: "Povinné",
        }),
        number: z.string(),
        valid: z.object({
          from: z.date(),
          to: z.date(),
        }),
        issued: z.string(),
      }),
    })
    .superRefine((obj, ctx) => {
      console.log({ validatedCountries });
      if (validatedCountries.includes(obj.country)) {
        const personalIdentificator = PersonalIdentification.getInfo(
          obj.personalIdentificationNumber,
        );

        if (!personalIdentificator.IsValid) {
          ctx.addIssue({
            code: "custom",
            message: "Neplatné rodné číslo",
            path: ["personalIdentificationNumber"],
          });
        }
      }
    });

export const useUserDetailValidationSchema = () => {
  const { validatedCountries } = usePersonalInfNumberValidatedCountries();
  const [schema, setSchema] = useState<z.ZodType<UserDetailModel>>(
    getUserDetailValidationSchema(validatedCountries),
  );

  useEffect(() => {
    setSchema(getUserDetailValidationSchema(validatedCountries));
  }, [validatedCountries]);

  return { userDetailValidationSchema: schema };
};
