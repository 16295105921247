import * as React from "react";
export const AlertIcon = (props: React.SVGProps<SVGSVGElement>) => {
  props = {
    ...props,
    color: props.color,
  };
  return (
    <svg
      width={20}
      height={19}
      viewBox="0 0 20 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M9.99997 0.527344C9.22274 0.527344 8.44552 0.906346 8.02536 1.66504L0.285129 15.6484C-0.53118 17.1234 0.574016 19.001 2.25974 19.001H17.7412C19.4269 19.001 20.5321 17.1234 19.7158 15.6484L11.9746 1.66504C11.5544 0.906346 10.7772 0.527344 9.99997 0.527344ZM9.99997 1.97559C10.2542 1.97559 10.5082 2.11379 10.6621 2.3916L18.4033 16.375C18.7 16.9111 18.3525 17.501 17.7412 17.501H2.25974C1.64846 17.501 1.30094 16.9111 1.59763 16.375L9.33786 2.3916C9.49171 2.11379 9.7457 1.97559 9.99997 1.97559ZM9.98825 5.99023C9.78951 5.99334 9.60013 6.0752 9.4617 6.21784C9.32327 6.36048 9.24712 6.55223 9.24997 6.75098V11.751C9.24857 11.8504 9.26693 11.949 9.30399 12.0413C9.34105 12.1335 9.39607 12.2174 9.46585 12.2882C9.53564 12.359 9.61879 12.4152 9.71048 12.4535C9.80218 12.4919 9.90058 12.5117 9.99997 12.5117C10.0994 12.5117 10.1978 12.4919 10.2895 12.4535C10.3812 12.4152 10.4643 12.359 10.5341 12.2882C10.6039 12.2174 10.6589 12.1335 10.696 12.0413C10.733 11.949 10.7514 11.8504 10.75 11.751V6.75098C10.7514 6.65059 10.7327 6.55094 10.6949 6.45793C10.6571 6.36492 10.6011 6.28044 10.53 6.20949C10.459 6.13855 10.3744 6.08259 10.2814 6.04493C10.1883 6.00726 10.0886 5.98867 9.98825 5.99023ZM9.99997 14.001C9.73476 14.001 9.4804 14.1063 9.29287 14.2939C9.10533 14.4814 8.99997 14.7358 8.99997 15.001C8.99997 15.2662 9.10533 15.5205 9.29287 15.7081C9.4804 15.8956 9.73476 16.001 9.99997 16.001C10.2652 16.001 10.5195 15.8956 10.7071 15.7081C10.8946 15.5205 11 15.2662 11 15.001C11 14.7358 10.8946 14.4814 10.7071 14.2939C10.5195 14.1063 10.2652 14.001 9.99997 14.001Z" />
    </svg>
  );
};
