import { Typography } from "@mui/material";
import QRCodeStyling from "qr-code-styling";
import React from "react";
import styled from "styled-components";

import mooneLogo from "Assets/Images/Images/moone_logo.png";

type Props = { paymentDescriptor: string; size?: number };

const Wrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: ${props => props.theme.spacing(3, 0)};
`;

export const InvoiceQRCode: React.FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ paymentDescriptor, size }) => {
  console.log({ paymentDescriptor });

  const ref = React.useRef<HTMLDivElement>(null);
  const [qrCode] = React.useState<QRCodeStyling>(
    new QRCodeStyling({
      width: size ?? 250,
      height: size ?? 250,
      data: paymentDescriptor,
      margin: 0,
      qrOptions: {
        typeNumber: 0,
        mode: "Byte",
        errorCorrectionLevel: "H",
      },
      imageOptions: {
        hideBackgroundDots: true,
        imageSize: 0.5,
        margin: 5,
      },
      dotsOptions: { type: "rounded", color: "#000000", gradient: undefined },
      backgroundOptions: { color: "#ffffff" },
      image: mooneLogo,
      cornersSquareOptions: { type: "extra-rounded", color: "#000000" },
      cornersDotOptions: { type: "dot", color: "#000000" },
      type: "svg",
    }),
  );

  React.useEffect(() => {
    qrCode.append(ref.current as any);
  }, [qrCode]);

  return (
    <Wrapper
      onClick={() =>
        qrCode.download({ extension: "png", name: "Platební údaje" })
      }
    >
      <div ref={ref} />
      <Typography fontSize={"12px"}>Uložít QR kód</Typography>
    </Wrapper>
  );
};
