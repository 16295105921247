import { Box, Typography } from "@mui/material";
import { FC } from "react";

type Props = {
  values: { label: string; value?: string | undefined }[];
};

export const ValueItems: FC<Props> = ({ values }) => {
  return (
    <>
      {values.map(({ label, value }, index) => (
        <Box
          key={index}
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <Typography fontWeight={400}>{label}</Typography>
          <Typography fontSize={16} fontWeight={500}>
            {value}
          </Typography>
        </Box>
      ))}
    </>
  );
};
