import { Gender } from "Infrastructure/Api/Api";
import { CodeListItem } from "Models/ICodeListDto";
import {
  ResourceDictionary,
  Resources,
  useResource,
} from "Translations/Resources";
import { useMemo } from "react";

type ListItem = CodeListItem<Gender>;
type Result = ListItem[];

const ResourceMap: {
  [key in Gender]: keyof ResourceDictionary["Form"]["PersonalData"]["Sex"];
} = {
  Female: "Female",
  Male: "Male",
};

export const useGenderList = (): Result => {
  const { t } = useResource();

  return useMemo<Result>(
    () =>
      Object.entries(ResourceMap).map<ListItem>(([key, value]) => ({
        code: key as Gender,
        name: t(Resources.Form.PersonalData.Sex[value]),
      })),
    [t],
  );
};
