import { useCountryList } from "Hooks/Options/useCountryList";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import { Dropdown } from "../Dropdown";

type Props<FormType extends FieldValues> = {
  name: FieldPath<FormType>;
  control: Control<FormType, object>;
  label?: string;
};

export const CountryDropdown = <FormType extends FieldValues>({
  control,
  name,
  label,
}: Props<FormType>) => {
  const { codeListCountries } = useCountryList();

  return (
    <Dropdown
      codeList={codeListCountries}
      name={name}
      control={control}
      label={label}
    />
  );
};
