import { Typography } from "@mui/material";
import {
  StyledFormControl,
  StyledFormInput,
  StyledFormLabelWrapper,
  StyledValidationText,
} from "Components/Shared/FormStyles";
import { Dropdown as DropdownInput } from "Components/Shared/Inputs/Dropdown";
import { FormInfo } from "Components/Shared/Inputs/FormInfo";
import { StyledInputLabel } from "Components/Shared/StyledComponents";
import { CodeListItem } from "Models/ICodeListDto";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

type Props<FormType extends FieldValues> = {
  codeList: CodeListItem[];
  name: FieldPath<FormType>;
  formControlClass?: "long" | "medium" | "tiny";
  label?: string;
  control: Control<FormType, object>;
  formInfo?: { [key: string]: string };
  disabled?: boolean;
  placeholder?: string;
};

export const Dropdown = <T extends object>(props: Props<T>) => {
  const {
    codeList,
    name,
    control,
    label,
    formInfo,
    disabled,
    formControlClass,
    placeholder,
  } = props;
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({
          field: { onChange, value, onBlur },
          fieldState: { error },
        }) => {
          const formValue = !!value
            ? codeList.some(e => e.id === value || e.code === value)
              ? (value as any)
              : ""
            : "";

          return (
            <>
              <StyledFormControl
                className={`${formControlClass} ${!label && "no-label"}`}
              >
                {!!label && (
                  <StyledFormLabelWrapper>
                    <StyledInputLabel htmlFor={name} hasError={!!error}>
                      <Typography>{label}</Typography>
                      <FormInfo name={name} formInfo={formInfo} />
                    </StyledInputLabel>
                  </StyledFormLabelWrapper>
                )}
                <StyledFormInput>
                  <DropdownInput
                    value={formValue}
                    onChange={val => {
                      const vv = isNaN(parseInt(val.toString()))
                        ? val
                        : Number(val);

                      return onChange(vv);
                    }}
                    onBlur={onBlur}
                    disabled={disabled}
                    hasError={!!error}
                    name={name}
                    placeholder={placeholder}
                    options={codeList.map(x => ({
                      Name: x.name,
                      Value: x.id ?? x.code,
                    }))}
                  ></DropdownInput>
                </StyledFormInput>
                <StyledValidationText>
                  {!!error && error.message}
                </StyledValidationText>
              </StyledFormControl>
            </>
          );
        }}
      />
    </>
  );
};
