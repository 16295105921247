import i18next from "i18next";
import type { ErrorResponse, FetchResponse, FetchResponseOfError } from "./Api";
import { toast } from "sonner";
import { getResourcePath, Resources } from "Translations/Resources";
import { captureEvent } from "@sentry/react";

type BaseResponse<TData = unknown> =
  | FetchResponse<TData, number>
  | ErrorResponse;

type SuccessResponse<TResponse extends BaseResponse> = Extract<
  TResponse,
  { status: 200 | 201 | 202 | 204 }
>;

type SuccessResponseData<TResponse extends BaseResponse> =
  SuccessResponse<TResponse>["data"];

export class ApiCallError extends Error {
  data: FetchResponseOfError;

  constructor(data: FetchResponseOfError) {
    super();
    this.data = data;
  }

  protected isApiException = true;

  static isApiException(obj: any): obj is ApiCallError {
    return obj?.isApiException === true;
  }
}

export const isFetchResponseOfError = (obj: any): obj is FetchResponseOfError =>
  !!obj.data && typeof obj.status === "number";

export const handleResponse = (
  response?: { data: unknown; status: number } | Error | null,
) => {
  if (!response) {
    return;
  }

  processResponseStatusCode(response as any);
};

export const handleResponseError = (error: any) => {
  const errorMessage = getMessageFromErrorResponse(error);
  const status = error?.data?.status;

  if (errorMessage && status >= 400) {
    toast.error(errorMessage);
  }

  if (status === undefined && error?.message) {
    // log to sentry
    captureEvent({
      message: `Error while useMutation: ${error.message}`,
      level: "log",
    });

    toast.error(error.message);
  }
};

function getMessageFromErrorResponse(response: any) {
  let errorMessage: string | undefined;
  let responseError = response;
  if (ApiCallError?.isApiException?.(responseError)) {
    responseError = responseError.data;
  }

  if (isFetchResponseOfError(responseError)) {
    responseError = responseError.data;
  }

  //if has property title
  if (responseError?.title?.length > 0) {
    return responseError.title;
  }

  if (responseError instanceof Error) {
    errorMessage = responseError.message;
  }

  return (
    errorMessage ?? i18next.t(getResourcePath(Resources.Errors.RequestError))
  );
}

/**
 *  Process response and throw error if status code is not 2xx
 * @param request request function
 * @returns Processed response
 */
export const processResponse =
  <TArgs = void, TResponse extends BaseResponse = BaseResponse>(
    request: (args: TArgs, headers?: Headers) => Promise<TResponse>,
  ) =>
  async (args: TArgs): Promise<SuccessResponseData<TResponse>> => {
    const response = await request(args);
    return processResponseStatusCode<TResponse>(response);
  };

/**
 * Process response and throw error if status code is not 2xx
 * @param request request function
 * @returns Processed response
 */
export const processResponseWithData =
  <T>(
    request: () => Promise<FetchResponse<{ Data: T }, 200> | ErrorResponse>,
  ) =>
  async () => {
    const response = await request();
    return processResponseStatusCode(response)?.Data;
  };

function processResponseStatusCode<TResponse extends BaseResponse>(
  response: TResponse,
): SuccessResponseData<TResponse> {
  if (response?.status >= 400 || response?.status === 0) {
    throw new ApiCallError(response as FetchResponseOfError);
  }

  return response?.data;
}
