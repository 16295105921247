import { useMutation } from "@tanstack/react-query";
import {
  apiPost,
  getApiUrl,
  getPartyPublicID,
  PostVerificationsUploadDocumentsFetchResponse,
  postVerificationsUploadDocumentsPath,
} from "Infrastructure/Api/Api";

type DocumentsType = {
  firstIDCardFrontSide: File;
  firstIDCardBackSide: File;
  secondIDCardFrontSide?: File;
};

export const useOCR = () =>
  useMutation({
    mutationFn: async (documents: DocumentsType) => {
      const form = new FormData();

      form.append("FirstIDCardFrontSide", documents.firstIDCardFrontSide);
      form.append("FirstIDCardBackSide", documents.firstIDCardBackSide);

      if (documents.secondIDCardFrontSide) {
        form.append("SecondIDCardFrontSide", documents.secondIDCardFrontSide);
      }

      const result = await (apiPost(
        `${getApiUrl()}${postVerificationsUploadDocumentsPath()}`,
        form,
        new Headers(),
      ) as Promise<PostVerificationsUploadDocumentsFetchResponse>);

      if (!result.data?.partyPublicID) {
        throw new Error("Chyba při zpracování dokumentů");
      }

      return await getPartyPublicID(result.data.partyPublicID);
    },
  });
