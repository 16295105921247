import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Typography } from "@mui/material";
import { useOCR } from "Api/Mutations/Onboarding/useOCR";
import {
  OnboardingSteps,
  PersonalDocumentModel,
  useOnboardingContext,
} from "Components/Onboarding/Context/OnboardingContext";
import { StepBox } from "Components/Onboarding/Steps/Components/StepBox";
import { getUserDetailFromOCRResult } from "Components/Onboarding/Steps/Utils/ocrUtils";
import { FormItemsBox } from "Components/Shared/FormItemsBox";
import { FullHeightForm } from "Components/Shared/FormStyles";
import { SearchAccountIcon } from "Components/Shared/Icons/SearchAccount";
import { BlFileInputForm } from "Components/Shared/Inputs/Form/BlFileInputForm";
import { logError } from "ErrorService";
import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import { Resources, useResource } from "Translations/Resources";
import { z } from "zod";

const StepResources = Resources.Onboarding.Steps.Documents;

export const DocumentsStep: FC = () => {
  const { t } = useResource();

  const [validationSchema] = useState<z.ZodType<PersonalDocumentModel>>(
    z.object({
      frontSide: z.instanceof(File, {
        message: t(StepResources.Validations.ForegroundMissing),
      }),
      backSide: z.instanceof(File, {
        message: t(StepResources.Validations.BackgroundMissing),
      }),
    }),
  );

  const { mutateAsync: ocrRequest, isPending: isProcessingOcr } = useOCR();
  const { nextStep, setFormValues, setPartyData, getFormValues } =
    useOnboardingContext();
  const { control, handleSubmit } = useForm<PersonalDocumentModel>({
    resolver: zodResolver(validationSchema),
    defaultValues: getFormValues(OnboardingSteps.PersonalDocument),
  });

  const onSubmit = handleSubmit(async data => {
    if (isProcessingOcr) {
      return;
    }

    setFormValues(OnboardingSteps.PersonalDocument, data);

    const result = await ocrRequest({
      firstIDCardBackSide: data.backSide,
      firstIDCardFrontSide: data.frontSide,
    });

    if (result) {
      if (!result.data?.publicID) {
        toast.error(t(StepResources.ProcessingFailed));
        logError(new Error("OCR failed - publicID missing"));

        return;
      }

      if (result.data) {
        setFormValues(
          OnboardingSteps.UserDetail,
          getUserDetailFromOCRResult(result.data),
        );

        setPartyData(result.data);
      }
      nextStep();
    }
  });

  if (isProcessingOcr) {
    return (
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box marginTop={12} marginBottom={2}>
          <SearchAccountIcon />
        </Box>
        <Typography variant="body2">{t(StepResources.Processing)}</Typography>
      </Box>
    );
  }

  return (
    <FullHeightForm onSubmit={onSubmit}>
      <StepBox
        title={t(StepResources.LegalInformationBlock1)}
        subTitle={t(StepResources.LegalInformationBlock2)}
        withDivider
        submitButtonLabel="Pokračovat"
      >
        <FormItemsBox>
          <BlFileInputForm
            control={control}
            label={t(StepResources.Forms.FrontSide)}
            name="frontSide"
            title={t(StepResources.Forms.DocumentTitle)}
            description={t(StepResources.Forms.DocumentDescription)}
          />
          <BlFileInputForm
            control={control}
            label={t(StepResources.Forms.BackSide)}
            name="backSide"
            title={t(StepResources.Forms.DocumentTitle)}
            description={t(StepResources.Forms.DocumentDescription)}
          />
        </FormItemsBox>
      </StepBox>
    </FullHeightForm>
  );
};
