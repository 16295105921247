import { CodeListItem } from "Models/ICodeListDto";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { RootStateType } from "State/Store";

export const useCountryList = () => {
  const countries = useSelector(
    (s: RootStateType) => s.codeList.codeLists?.countries,
  );

  const codeListCountries = useMemo<CodeListItem[]>(
    () => countries?.map(({ id, name }) => ({ code: id, name })) ?? [],
    [countries],
  );

  return {
    isLoading: false,
    countries: countries ?? [],
    codeListCountries,
  };
};
