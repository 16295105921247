import { useMutation } from "@tanstack/react-query";
import { PartyDto, putPartyPublicID } from "Infrastructure/Api/Api";
import { processResponse } from "Infrastructure/Api/ApiUtils";

type Props = {
  onSuccess?: () => void;
};

export const usePartyMutation = ({ onSuccess }: Props = {}) =>
  useMutation({
    mutationFn: processResponse(
      ({ publicID, data }: { publicID: string; data: PartyDto }) =>
        putPartyPublicID(data, publicID),
    ),
    onSuccess: () => {
      onSuccess?.();
    },
  });
